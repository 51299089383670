import { httpGet } from "./HttpService";

export interface IUrl {
    url: string;
}

export const getFileDownloadUrl = async (id: string, fileName: string) => {
    const result = await httpGet<IUrl>(`/attachments/${id}`, { fileName: fileName });

    return result;
}