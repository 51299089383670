import { Button, Flex, List, StepProps, Steps } from "antd";
import { IOrderItemGroupV2, IOrderItemStepHistory, OrderItemStep, getOrderItemStepName } from "../services/OrderService";
import { displayDateTime } from "../utilities/FormatUtil";
import { safetyHtmlValue } from "../utilities/ValueUtil";

const OrderItemProgress: React.FC<{ item: IOrderItemGroupV2, onClickEditButton: (stepHistory: IOrderItemStepHistory) => void, onClickDeleteButton: (itemIds: Array<string>, stepHistory: IOrderItemStepHistory) => void }> = ({ item, onClickEditButton, onClickDeleteButton }) => {
    const createItem = (step: OrderItemStep): StepProps => {
        const histories = item.stepHistories.filter(h => h.step === step);
        const stepName = getOrderItemStepName(step);

        return {
            title: stepName,
            description: histories.length > 0 && <List dataSource={histories} itemLayout="horizontal"
                renderItem={
                    (history, index) => {
                        return <List.Item key={index}>
                            <List.Item.Meta
                                title={displayDateTime(history.utcCreatedDate)}
                                description={
                                    <Flex gap="small" vertical={true}>
                                        <div dangerouslySetInnerHTML={{ __html: safetyHtmlValue(history.description ?? "") }} />
                                        <Flex gap="small" justify="end">
                                            <Button type="primary" ghost={true} size="small" onClick={() => onClickEditButton(history)}>수정</Button>
                                            <Button type="default" danger={true} size="small" onClick={() => onClickDeleteButton(item.ids, history)}>삭제</Button>
                                        </Flex>
                                    </Flex>
                                } />
                        </List.Item>
                    }
                }
            />
        }
    };

    return (
        <Steps
            status={item.currentStep === OrderItemStep.Complete ? "finish" : "process"}
            direction="horizontal"
            current={item.currentStep - 2}
            items={
                [
                    createItem(OrderItemStep.Request),
                    createItem(OrderItemStep.Procedure),
                    createItem(OrderItemStep.Screening),
                    createItem(OrderItemStep.Report),
                    createItem(OrderItemStep.Complete),
                ]
            }
        />
    );
}

export default OrderItemProgress;