import axios, { AxiosResponse } from 'axios';
import { AuthStorageService } from './AuthStorageService';

export interface IPaginatable<T> {
    pageSize: number;
    previousPageIndex: number;
    currentPageIndex: number;
    nextPageIndex: number;
    totalItemCount: number;
    totalPageCount: number;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
    items: Array<T>;
}

export type ApiErrorInfo = {
    [key: string]: string[];
} | null;

const errorMessages: { [key: number]: string } = {
    401: "유효한 인증 정보가 없어 요청이 거부되었습니다.",
    403: "리소스 접근 권한이 없어 요청이 거부되었습니다.",
    404: "리소스가 존재하지 않습니다.",
    999: "알 수 없는 오류로 요청이 실패했습니다."
};

export const httpInit = () => {
    axios.interceptors.request.use((config) => {
        config.baseURL = "https://admin-api.markerslab.app";
        // config.baseURL = "http://10.211.55.7:7000";

        const authModel = AuthStorageService.load();
        if (authModel.accessToken) {
            config.headers.Authorization = `Bearer ${authModel.accessToken}`;
        }

        config.validateStatus = (status) => {
            return status < 503;
        }

        return config;
    });

    axios.interceptors.response.use(
        (response) => {
            return response;
        }
    );
}

const getErrorMessage = (response: AxiosResponse<any, any>) => {
    const message = (Object.keys(errorMessages).includes(`${response.status}`)
        ? errorMessages[response.status]
        : response.statusText) ?? errorMessages[999]!;

    return message;
}

interface IApiEmptyResponse {
    isSuccess: boolean;
    statusCode: number;
    error: ApiErrorInfo;
}


interface IApiResponse<T> extends IApiEmptyResponse {
    dto?: T | null;
}

const processEmptyResult = (response: AxiosResponse<any, any>): IApiEmptyResponse => {
    switch (response.status) {
        case 200:
            return {
                isSuccess: true,
                statusCode: response.status,
                error: null
            }
        case 400:
        case 500:
            return {
                isSuccess: false,
                statusCode: response.status,
                error: response.data
            }
        default:
            return {
                isSuccess: false,
                statusCode: response.status,
                error: {
                    errors: [getErrorMessage(response)]
                }
            }
    }
}

export const httpGetEmpty = async (url: string, params?: any) => {
    const response = await axios.get(url, { params: params });

    return processEmptyResult(response);
};

export const httpPostEmpty = async (url: string, data?: any, params?: any) => {
    const response = await axios.post(url, data, { params: params });

    return processEmptyResult(response);
};

export const httpPutEmpty = async (url: string, data?: any, params?: any) => {
    const response = await axios.put(url, data, { params: params });

    return processEmptyResult(response);
};

export const httpDeleteEmpty = async (url: string, params?: any) => {
    const response = await axios.delete(url, { params: params });

    return processEmptyResult(response);
};

const processResult = <T>(response: AxiosResponse<any, any>): IApiResponse<T> => {
    switch (response.status) {
        case 200:
            return {
                isSuccess: true,
                statusCode: response.status,
                dto: response.data as T,
                error: null
            }
        case 400:
        case 500:
            return {
                isSuccess: false,
                statusCode: response.status,
                error: response.data,
            }
        default:
            return {
                isSuccess: false,
                statusCode: response.status,
                error: {
                    errors: [getErrorMessage(response)]
                }
            }
    }
}

export const httpGet = async <T>(url: string, params?: any) => {
    const response = await axios.get(url, { params: params });

    return processResult<T>(response);
};

export const httpPost = async <T>(url: string, data?: any, params?: any) => {
    const response = await axios.post(url, data, { params: params });

    return processResult<T>(response);
};

export const httpPut = async <T>(url: string, data?: any, params?: any) => {
    const response = await axios.put(url, data, { params: params });

    return processResult<T>(response);
};

export const httpDelete = async <T>(url: string, params?: any) => {
    const response = await axios.delete(url, { params: params });

    return processResult<T>(response);
};