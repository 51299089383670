export const htmlEncode = (rawHtml: string) => {
    return rawHtml
        .replace(/&/g, '&amp;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#39;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;');
};

export const safetyHtmlValue = (rawHtml: string) => {
    return htmlEncode(rawHtml).replace(/\n/gi, "<br />");
};