import React, { useEffect, useState } from 'react';
import { IOrderRow, OrderPaymentStatus, getDefaultOrderSearchBindingModel, getOrders } from '../services/OrderService';
import { showErrorModal } from '../components/MModal';
import { Button, Col, Descriptions, Form, Input, Row, Space, Table, theme } from 'antd';
import { useRecoilState } from 'recoil';
import LoadingState from '../states/LoadingState';
import { displayDateTime, displayPrice } from '../utilities/FormatUtil';
import { ColumnsType } from 'antd/es/table';
import OrderPaymentStatusTag from '../components/OrderPaymentStatusTag';
import OrderSearchState from '../states/OrderSearchState';
import { useNavigate } from 'react-router-dom';
import { scrollToTop } from '../utilities/ScrollUtil';
import { TablePaginationConfig } from 'antd/lib';

const Order: React.FC = () => {
    const [orders, setOrders] = useState<Array<IOrderRow>>([]);
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [, setLoadingState] = useRecoilState(LoadingState);
    const [orderSearchState, setOrderSearchState] = useRecoilState(OrderSearchState);
    const navigate = useNavigate();
    const { token } = theme.useToken();
    const [form] = Form.useForm();

    const handleChangedIndex = (index: number, pageSize: number) => {
        scrollToTop();
        setOrderSearchState({ ...orderSearchState, pageIndex: index - 1, pageSize: pageSize });
    };

    const columns: ColumnsType<IOrderRow> = [
        {
            title: "주문번호",
            dataIndex: "publicNumber",
            render: (value: number, record) => <Button type="link" onClick={() => navigate(`/order/${record.id}`)}>{value}</Button>
        },
        {
            title: "상표명",
            render: (_, record) => <span>{record.koreanTrademarkName}<br />{record.englishTrademarkName}</span>
        },
        {
            title: "계정",
            render: (_, record) => <span>{record.fullName} ({record.userName})</span>
        },
        {
            title: "담당자",
            dataIndex: "operator.fullName",
            key: "operator.fullName",
            render: (_, record) => record.operator.fullName
        },
        {
            title: "출원인",
            render: (_, record) => record.applicant.koreanFullName
        },
        {
            title: "금액",
            dataIndex: "totalAmountWIthVAT",
            align: "right",
            render: (value: number) => displayPrice(value)
        },
        {
            title: "등록일",
            dataIndex: "utcCreatedDate",
            render: (value: string) => displayDateTime(value)
        },
        {
            title: "결제",
            dataIndex: "paymentStatus",
            render: (value: OrderPaymentStatus) => <OrderPaymentStatusTag status={value} />
        }
    ];

    const pagination: TablePaginationConfig = {
        total: totalItemCount,
        pageSize: orderSearchState.pageSize,
        pageSizeOptions: ["10", "20", "50"],
        current: (orderSearchState.pageIndex ?? 0) + 1,
        onChange: handleChangedIndex
    };

    useEffect(() => {
        const loadOrders = async () => {
            setLoadingState(true);
            const result = await getOrders(orderSearchState);
            if (result.isSuccess) {
                setOrders(result.dto!.items);
                setTotalItemCount(result.dto!.totalItemCount);
            }
            else {
                showErrorModal(result.error);
            }
            setLoadingState(false);
        }

        form.setFieldsValue(orderSearchState);

        loadOrders();
    }, [orderSearchState, form, setLoadingState]);

    return (
        <>
            <Form
                form={form}
                layout="horizontal" style={{ backgroundColor: token.colorFillAlter, borderRadius: token.borderRadiusLG, padding: 24, marginBottom: 16 }}
                onFinish={(values: any) => setOrderSearchState({ ...orderSearchState, ...values, pageIndex: 0 })}
                onReset={() => setOrderSearchState(getDefaultOrderSearchBindingModel())}>
                <Row gutter={[32, 0]}>
                    <Col span={12}>
                        <Form.Item label="상표명(한글)" name="koreanTrademarkName">
                            <Input maxLength={400} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="상표명(영문)" name="englishTrademarkName">
                            <Input maxLength={400} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="계정" name="user">
                            <Input maxLength={300} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="담당자" name="orderOperator">
                            <Input maxLength={300} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="출원인" name="applicant">
                            <Input maxLength={300} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="특허 고객 번호" name="applicantCode">
                            <Input maxLength={100} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="추천인 코드" name="recommendation">
                            <Input maxLength={100} />
                        </Form.Item>
                    </Col>
                </Row>
                <div style={{ textAlign: "right" }}>
                    <Space size="small" direction="horizontal">
                        <Button type="primary" htmlType="submit">검색</Button>
                        <Button htmlType="reset">리셋</Button>
                    </Space>
                </div>
            </Form>
            <Descriptions>
                <Descriptions.Item label="검색결과">{totalItemCount}</Descriptions.Item>
            </Descriptions>
            <Table columns={columns} dataSource={orders} rowKey={(r) => r.id} pagination={pagination} />
        </>
    );
}

export default Order;